.btn {
  height: 55px;
  padding: 17px 20px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  transition: background-color 0.3s ease;

  &--accent {
    color: var(--basic-black);
    background-color: var(--accent-btn-bg);

    @media (hover: hover),
    screen and (min-width: 0\0) {
      &:hover {
        background-color: #b4ff15;
      }
    }
  }
}
