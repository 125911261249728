.team-build {
  padding-top: 60px;
  padding-bottom: 60px;
}

.team-build .container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.team-build__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.team-build .title--l {
  position: relative;
  margin-bottom: 15px;

  &::after {
    content: "";
    position: absolute;
    top: -15px;
    right: -30px;
    transform: rotate(90deg);
    width: 27px;
    height: 28px;
    background-image: url("../img/decorative/highlight-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.team-build__text {
  margin: 0;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
}

.team-build__features {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    column-gap: 12px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    color: var(--basic-black);

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #00c808;
      background-image: url("../img/icons/check-white-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
    }
  }
}

@media (min-width: 990px) {
  .team-build .container {
    flex-direction: row-reverse;
    column-gap: 30px;
  }
}

@include vp-1200 {
  .team-build {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .team-build .container {
    column-gap: 80px;
  }

  .team-build .title--l {
    margin-bottom: 25px;

    &::after {
      top: -42px;
      right: -40px;
      width: 40px;
      height: 43px;
    }
  }

  .team-build__text {
    font-size: 18px;
    line-height: 1.4;
  }

  .team-build__features {
    row-gap: 20px;

    li {
      column-gap: 20px;
      font-size: 18px;

      &::before {
        width: 24px;
        height: 24px;
      }
    }
  }

  .team-build picture {
    flex-shrink: 0;
  }

  .team-build .link {
    margin-left: 44px;
  }
}

@include vp-1440 {
  .team-build .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
