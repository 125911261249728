.link {
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  color: var(--link-color);
  transition: color 0.3s ease;

  svg {
    fill: none;
    stroke: var(--link-color);
    transition: stroke 0.3s ease;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      color: #0057d2;
    }

    &:hover svg {
      stroke: #0057d2;
    }
  }
}
