.title {
  margin: 0;
  font-weight: 700;
  line-height: 1.2;

  &--xl {
    font-size: 42px;
  }

  &--l {
    font-size: 36px;
  }

  &--m {
    font-size: 30px;
  }
}

@include vp-1200 {
  .title--xl {
    font-size: 58px;
  }

  .title--l {
    font-size: 44px;
  }

  .title--m {
    font-size: 36px;
  }
}
