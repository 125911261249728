.socials {
  display: flex;
  column-gap: 10px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.socials__link {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  background-color: #f2f4f6;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  svg {
    width: 21px;
    height: 20px;
    fill: var(--basic-black);
    transition: fill 0.3s ease;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      background-color: var(--main-blue);
    }

    &:hover svg {
      fill: var(--basic-white);
    }
  }
}

@include vp-1200 {
  .socials__link {
    width: 46px;
    height: 46px;

    svg {
      width: 27px;
      height: 26px;
    }
  }
}
