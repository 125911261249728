.footer {
  padding-bottom: 30px;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  padding-top: 30px;
  border-top: 1px solid var(--basic-black);
}

.footer__logo {
  width: 109px;
  height: 27px;
}

.footer__copyright {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: rgba($color: #101828, $alpha: 0.4);
}

@include vp-768 {
  .footer__content {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
  }
}

@include vp-1200 {
  .footer {
    padding-bottom: 65px;
  }

  .footer__content {
    padding-top: 65px;
  }

  .footer__logo {
    width: 151px;
    height: 38px;
  }

  .footer__copyright {
    font-size: 15px;
  }
}

@include vp-1440 {
  .footer .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
