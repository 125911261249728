.field {
  position: relative;
}

.field__label {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
  transition: font-size 0.3s ease, top 0.3s ease, color 0.3s ease;
}

.field__input {
  width: 100%;
  height: 56px;
  padding: 22px 16px 9px;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  color: var(--basic-black);
  background-color: #f0f3f5;
  border: 1px solid transparent;
  border-radius: 10px;
  outline: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover,
  &:focus-visible {
    background-color: var(--basic-white);
    border-color: var(--main-blue);
  }

  &--err {
    background-color: white;
    border-color: #ff002e;
  }
}

.field__input:focus + .field__label,
.field__input:valid + .field__label,
.field__input:read-only + .field__label {
  top: 8px;
  font-size: 12px;
  line-height: 1.3;
  color: var(--main-blue);
}

textarea.field__input {
  height: 110px;
}

.field__err-msg {
  display: none;
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.3;
  color: #ff002e;
}

.field__input--err ~ .field__err-msg {
  display: inline-block;
}

.field__err-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;
  width: 24px;
  height: 24px;
  background-image: url("../img/icons/valid-err-icon.svg");
  background-repeat: no-repeat;
}

.field__input--err ~ .field__err-icon {
  display: block;
}
