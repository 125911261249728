.contacts {
  padding-bottom: 60px;
}

.contacts__map {
  height: 550px;
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1440px 550px;

  &--en {
    background-image: url("../img/map/webp/map-en@1x.webp");

    @include retina {
      background-image: url("../img/map/webp/map-en@2x.webp");
    }
  }

  &--ru {
    background-image: url("../img/map/webp/map-ru@1x.webp");

    @include retina {
      background-image: url("../img/map/webp/map-ru@2x.webp");
    }
  }
}

.contacts__info {
  position: relative;
  margin-top: -200px;
  padding: 40px 25px;
  scroll-margin-top: 45px;
  background-color: var(--basic-white);
  border-radius: 32px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.06);
}

.contacts__info .title--m {
  margin-bottom: 30px;
}

.contacts__content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.contacts__form {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.contacts__contact {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  margin: 0;
}

.contacts__contact-name {
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--text-color);
}

.contacts__contact-desc {
  margin-left: 0;
}

.contacts__contact-desc a[href^="tel:"] {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 600;
  color: var(--basic-black);
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      text-decoration-color: currentColor;
    }
  }
}

.contacts__contact-desc a[href^="mailto:"] {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: var(--main-blue);
  text-decoration: underline;
  transition: color 0.3s ease;

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      color: #0057d2;
    }
  }
}

.contacts__contact-desc address {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  font-style: normal;
  color: var(--basic-black);
}

@media (min-width: 990px) {
  .contacts__content {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 80px;
  }

  .contacts__form {
    flex-grow: 1;
    order: 1;
    max-width: 630px;
  }

  .contacts__form-btn {
    max-width: 375px;
  }
}

@include vp-1200 {
  .contacts__info {
    padding: 50px 80px;
  }

  .contacts__contact {
    row-gap: 20px;
  }

  .contacts__contact-name {
    font-size: 16px;
    line-height: 1.2;
  }

  .contacts__contact-desc a[href^="tel:"] {
    font-size: 28px;
  }

  .contacts__contact-desc a[href^="mailto:"] {
    font-size: 18px;
    line-height: 1.4;
  }

  .contacts__contact-desc address {
    font-size: 18px;
    line-height: 1.4;
  }
}

#messageSent {
  height: auto;
  background-color: #deff98;
  display: none;
  transition: .3s all ease;
}

.grecaptcha-badge {display: none !important;}

#contactsEmail.field__input--err + label {
  top: 8px;
  font-size: 12px;
  line-height: 1.3;
  color: var(--main-blue);
}
