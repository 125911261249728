:root {
  //  Colors
  --basic-white: #ffffff;
  --basic-black: #101828;
  --grey: #667085;
  --light-green: #abef19;
  --main-blue: #0069ff;

  --promo-bg: var(--basic-black);
  --accent-btn-bg: var(--light-green);
  --text-color: var(--grey);
  --link-color: var(--main-blue);
  --banner-bg: var(--basic-black);

  // Indents
  --content-padding-mobile: 20px;
  --content-padding-tablet: 35px;
  --content-padding-desctop: 50px;
}

// Viewports
// ---------------------------------

$vp-768: 768px;
$vp-1200: 1200px;
$vp-1440: 1440px;

// Retina
// ---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
