.achievements {
  padding-top: 50px;
  padding-bottom: 50px;
  scroll-margin-top: 50px;
}

.achievements__list {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.achievements__item {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.achievements__term {
  font-size: 42px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--main-blue);
}

.achievements__desc {
  margin: 0;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  color: var(--text-color);
}

@include vp-768 {
  .achievements__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
  }
}

@include vp-1200 {
  .achievements {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .achievements__item {
    row-gap: 10px;
  }

  .achievements__term {
    font-size: 58px;
  }

  .achievements__desc {
    font-size: 18px;
    line-height: 1.4;
  }
}

@include vp-1440 {
  .achievements .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
