.team {
  padding-top: 60px;
  padding-bottom: 60px;
}

.team .container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.team__info {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.team .title--l {
  position: relative;
  padding-bottom: 40px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 101px;
    height: 15px;
    background-image: url("../img/decorative/border-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.team__text {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
}

.team__info .link {
  display: none;
}

.team__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: center;
  column-gap: 24px;
  row-gap: 36px;
  margin: 0;
  margin-bottom: 41px;
  padding: 0;
  list-style: none;
}

.team__item {
  position: relative;
}

.team__item-img {
  width: 150px;
  height: 220px;
  object-fit: cover;
  border-radius: 20px;
}

.team__item-info {
  position: absolute;
  bottom: -11px;
  left: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 5px 11px 0 0;
  background-color: var(--basic-white);
  border-top-right-radius: 10px;
}

.team__item-name {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 600;
  color: var(--basic-black);
}

.team__item-post {
  font-size: 10px;
  line-height: 1.6;
  font-weight: 700;
  text-transform: uppercase;
  color: #cccccc;
}

@include vp-1200 {
  .team {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .team .container {
    flex-direction: row;
    column-gap: 80px;
  }

  .team .title--l {
    padding-bottom: 45px;

    &::after {
      width: 142px;
      height: 20px;
    }
  }

  .team__text {
    font-size: 18px;
    line-height: 1.4;
  }

  .team__info .link {
    display: flex;
  }

  .team__right {
    flex-shrink: 0;
  }

  .team__list {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    row-gap: 68px;
    margin-bottom: 0;
  }

  .team__item-img {
    width: 194px;
  }

  .team__item-info {
    bottom: -28px;
    padding: 8px 16px 0 0;
  }

  .team__item-name {
    font-size: 18px;
    line-height: 1.5;
  }

  .team__item-post {
    font-size: 13px;
    line-height: 1.7;
  }

  .team__right .link {
    display: none;
  }
}

@include vp-1440 {
  .team .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
