.projects__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 25px;
  background-color: #f0f3f5;
  border-radius: 32px;
}

.projects .title--l {
  position: relative;
  margin-bottom: 15px;

  &::after {
    content: "";
    position: absolute;
    top: -16px;
    right: 0;
    transform: rotate(90deg);
    width: 26px;
    height: 28px;
    background-image: url("../img/decorative/highlight-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  br {
    display: none;
  }
}

.projects__title-desk {
  display: none;
}

.projects__text {
  margin: 0;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
}

.projects__list {
  display: grid;
  row-gap: 25px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.projects__item {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 25px;
  background-color: var(--basic-white);
  border-radius: 20px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.06);
}

.projects__item-desk {
  margin: 0;
  font-size: 15px;
  line-height: 1.5;
  color: var(--text-color);
}

@media (min-width: 449px) {
  html[lang="en"] .projects .title--l::after {
    right: -20px;
  }
}

@media (min-width: 500px) {
  html[lang="ru"] .projects .title--l::after {
    right: -20px;
  }
}

@include vp-768 {
  .projects__title-mob {
    display: none;
  }

  .projects__title-desk {
    display: block;
  }

  html[lang="ru"] .projects .title--l::after {
    right: 0;
  }

  .projects__list {
    grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
    column-gap: 30px;
  }

  .projects__item-desk {
    margin-bottom: auto;
  }
}

@include vp-1200 {
  .projects__content {
    align-items: center;
    padding: 80px;
  }

  .projects .title--l {
    margin-bottom: 25px;
    text-align: center;

    &::after {
      right: auto;
      left: 0;
      top: -27px;
      width: 40px;
      height: 43px;
      transform: rotate(0);
    }

    br {
      display: block;
    }
  }

  html[lang=ru] .projects .title--l::after {
    right: auto;
    left: 100px;
  }

  .projects__text {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
  }

  .projects__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
