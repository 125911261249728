.main-nav__list {
  display: flex;
  align-items: center;
  column-gap: 35px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav__item-desk {
  display: none;
}

.main-nav__link {
  font-size: 12px;
  line-height: 1.6;
  font-weight: 700;
  color: var(--basic-white);
  text-decoration: underline;
  text-decoration-color: transparent;
  text-transform: uppercase;
  transition: text-decoration-color 0.3s ease, color 0.3s ease;

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      text-decoration-color: currentColor;
    }
  }
}

@include vp-768 {
  .main-nav__item-desk {
    display: block;
  }
}

@include vp-1200 {
  .main-nav__link {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
    text-transform: capitalize;
  }
}
