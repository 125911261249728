@mixin retina {
  @media (min-resolution: $retina-dpi),
  (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin vp-1440 {
  @media (min-width: $vp-1440) {
    @content;
  }
}

@mixin vp-1200 {
  @media (min-width: $vp-1200) {
    @content;
  }
}

@mixin vp-768 {
  @media (min-width: $vp-768) {
    @content;
  }
}
