.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 14px 0 13px;
  border-bottom: 1px solid transparent;
  transition: background-color 0.3s ease, padding 0.3s ease;

  &--fixed {
    background-color: var(--basic-white);
    border-bottom-color: rgba(0, 0, 0, 0.1);
  }
}

.header .container {
  display: flex;
  align-items: center;
}

.header__logo {
  margin-right: auto;

  svg {
    width: 110px;
    height: 26px;
  }
}

.header__logo-text {
  fill: var(--basic-white);
  transition: fill 0.3s ease;
}

.header--fixed .header__logo-text {
  fill: var(--basic-black);
}

.header .main-nav {
  margin-right: 25px;
}

.header--fixed .main-nav__link {
  color: var(--basic-black);
}

.header--fixed .lang__btn {
  color: var(--basic-black);
  border-color: var(--basic-black);

  svg {
    stroke: var(--basic-black);
  }
}

@include vp-1200 {
  .header {
    padding: 45px 0 44px;

    &--fixed {
      padding-top: 10px;
      padding-bottom: 9px;
    }
  }

  .header__logo svg {
    width: 151px;
    height: 38px;
  }

  .header .main-nav {
    margin-right: 30px;
  }
}

@include vp-1440 {
  .header .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
