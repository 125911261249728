.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--content-padding-mobile);

  @include vp-768 {
    padding: 0 var(--content-padding-tablet);
  }

  @include vp-1200 {
    padding: 0 var(--content-padding-desctop);
  }
}
