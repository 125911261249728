:root {
  --basic-white: #ffffff;
  --basic-black: #101828;
  --grey: #667085;
  --light-green: #abef19;
  --main-blue: #0069ff;
  --promo-bg: var(--basic-black);
  --accent-btn-bg: var(--light-green);
  --text-color: var(--grey);
  --link-color: var(--main-blue);
  --banner-bg: var(--basic-black);
  --content-padding-mobile: 20px;
  --content-padding-tablet: 35px;
  --content-padding-desctop: 50px;
}

/* cyrillic */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyCkIT4ttDfCmxA.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyCkIT4ttDfCmxA.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyCkIT4ttDfCmxA.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyCkIT4ttDfCmxA.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  width: 100%;
  height: 100%;
  min-width: 375px;
  font-family: "Raleway", "Arial", sans-serif;
  font-feature-settings: "pnum" on, "lnum" on;
}

a {
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

input,
textarea {
  border-radius: 0;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
}
input:invalid,
textarea:invalid {
  box-shadow: none;
}

select {
  border-radius: 0;
  appearance: none;
  color: inherit;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type=number] {
  appearance: textfield;
}

select,
textarea,
input:matches([type=email],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=url]) {
  appearance: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  appearance: none;
}

.v-h {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.btn-reset {
  padding: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--content-padding-mobile);
}
@media (min-width: 768px) {
  .container {
    padding: 0 var(--content-padding-tablet);
  }
}
@media (min-width: 1200px) {
  .container {
    padding: 0 var(--content-padding-desctop);
  }
}

.title {
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
}
.title--xl {
  font-size: 42px;
}
.title--l {
  font-size: 36px;
}
.title--m {
  font-size: 30px;
}

@media (min-width: 1200px) {
  .title--xl {
    font-size: 58px;
  }
  .title--l {
    font-size: 44px;
  }
  .title--m {
    font-size: 36px;
  }
}
.btn {
  height: 55px;
  padding: 17px 20px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}
.btn--accent {
  color: var(--basic-black);
  background-color: var(--accent-btn-bg);
}
@media (hover: hover), screen and (min-width: 0\0 ) {
  .btn--accent:hover {
    background-color: #b4ff15;
  }
}

.link {
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  color: var(--link-color);
  transition: color 0.3s ease;
}
.link svg {
  fill: none;
  stroke: var(--link-color);
  transition: stroke 0.3s ease;
}
@media (hover: hover), screen and (min-width: 0\0 ) {
  .link:hover {
    color: #0057d2;
  }
  .link:hover svg {
    stroke: #0057d2;
  }
}

.socials {
  display: flex;
  column-gap: 10px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.socials__link {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  background-color: #f2f4f6;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.socials__link svg {
  width: 21px;
  height: 20px;
  fill: var(--basic-black);
  transition: fill 0.3s ease;
}
@media (hover: hover), screen and (min-width: 0\0 ) {
  .socials__link:hover {
    background-color: var(--main-blue);
  }
  .socials__link:hover svg {
    fill: var(--basic-white);
  }
}

@media (min-width: 1200px) {
  .socials__link {
    width: 46px;
    height: 46px;
  }
  .socials__link svg {
    width: 27px;
    height: 26px;
  }
}
.field {
  position: relative;
}

.field__label {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
  transition: font-size 0.3s ease, top 0.3s ease, color 0.3s ease;
}

.field__input {
  width: 100%;
  height: 56px;
  padding: 22px 16px 9px;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  color: var(--basic-black);
  background-color: #f0f3f5;
  border: 1px solid transparent;
  border-radius: 10px;
  outline: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.field__input:hover, .field__input:focus-visible {
  background-color: var(--basic-white);
  border-color: var(--main-blue);
}
.field__input--err {
  background-color: white;
  border-color: #ff002e;
}

.field__input:focus + .field__label,
.field__input:valid + .field__label,
.field__input:read-only + .field__label {
  top: 8px;
  font-size: 12px;
  line-height: 1.3;
  color: var(--main-blue);
}

textarea.field__input {
  height: 110px;
}

.field__err-msg {
  display: none;
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.3;
  color: #ff002e;
}

.field__input--err ~ .field__err-msg {
  display: inline-block;
}

.field__err-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;
  width: 24px;
  height: 24px;
  background-image: url("../img/icons/valid-err-icon.svg");
  background-repeat: no-repeat;
}

.field__input--err ~ .field__err-icon {
  display: block;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 14px 0 13px;
  border-bottom: 1px solid transparent;
  transition: background-color 0.3s ease, padding 0.3s ease;
}
.header--fixed {
  background-color: var(--basic-white);
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.header .container {
  display: flex;
  align-items: center;
}

.header__logo {
  margin-right: auto;
}
.header__logo svg {
  width: 110px;
  height: 26px;
}

.header__logo-text {
  fill: var(--basic-white);
  transition: fill 0.3s ease;
}

.header--fixed .header__logo-text {
  fill: var(--basic-black);
}

.header .main-nav {
  margin-right: 25px;
}

.header--fixed .main-nav__link {
  color: var(--basic-black);
}

.header--fixed .lang__btn {
  color: var(--basic-black);
  border-color: var(--basic-black);
}
.header--fixed .lang__btn svg {
  stroke: var(--basic-black);
}

@media (min-width: 1200px) {
  .header {
    padding: 45px 0 44px;
  }
  .header--fixed {
    padding-top: 10px;
    padding-bottom: 9px;
  }
  .header__logo svg {
    width: 151px;
    height: 38px;
  }
  .header .main-nav {
    margin-right: 30px;
  }
}
@media (min-width: 1440px) {
  .header .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
.main-nav__list {
  display: flex;
  align-items: center;
  column-gap: 35px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav__item-desk {
  display: none;
}

.main-nav__link {
  font-size: 12px;
  line-height: 1.6;
  font-weight: 700;
  color: var(--basic-white);
  text-decoration: underline;
  text-decoration-color: transparent;
  text-transform: uppercase;
  transition: text-decoration-color 0.3s ease, color 0.3s ease;
}
@media (hover: hover), screen and (min-width: 0\0 ) {
  .main-nav__link:hover {
    text-decoration-color: currentColor;
  }
}

@media (min-width: 768px) {
  .main-nav__item-desk {
    display: block;
  }
}
@media (min-width: 1200px) {
  .main-nav__link {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
    text-transform: capitalize;
  }
}
.lang {
  position: relative;
}

.lang__btn {
  display: flex;
  align-items: center;
  column-gap: 4px;
  height: 32px;
  padding: 5px 8px 5px 6px;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--basic-white);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;
}
.lang__btn svg {
  width: 20px;
  height: 20px;
  fill: none;
  stroke: var(--basic-white);
  transition: stroke 0.3s ease;
}
@media (hover: hover), screen and (min-width: 0\0 ) {
  .lang__btn:hover {
    color: var(--basic-black);
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
  .lang__btn:hover svg {
    stroke: var(--basic-black);
  }
}

.lang__dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  display: none;
  flex-direction: column;
  row-gap: 4px;
  width: 170px;
  margin: 0;
  padding: 10px;
  list-style: none;
  background-color: var(--basic-white);
  border-radius: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.lang__dropdown--show {
  display: flex;
}

.lang__link {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 500;
  color: #000000;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}
@media (hover: hover), screen and (min-width: 0\0 ) {
  .lang__link:hover {
    background-color: #f4f4f4;
  }
}
.lang__link::before {
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}
.lang__link--ru::before {
  background-image: url("../img/flags/ru-flag-icon.svg");
}
.lang__link--en::before {
  background-image: url("../img/flags/usa-flag-icon.svg");
}
.lang__link--current::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 9px;
  height: 8px;
  background-image: url("../img/icons/check-lang-icon.svg");
}

@media (min-width: 1200px) {
  .lang__btn {
    column-gap: 4px;
    height: 40px;
    padding: 8px 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .lang__btn svg {
    width: 24px;
    height: 24px;
  }
}
.promo {
  padding: 100px 0 60px;
  background-color: var(--promo-bg);
}

.promo .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.promo .title--xl {
  position: relative;
  margin-bottom: 20px;
  color: var(--basic-white);
}
.promo .title--xl::after {
  content: "";
  position: absolute;
  top: -15px;
  right: 0;
  width: 74px;
  height: 44px;
  background-image: url("../img/decorative/highlight-accent-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.promo__text {
  max-width: 700px;
  margin: 0;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}

.promo__directions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 25px;
  margin: 0;
  margin-bottom: 50px;
  padding: 0;
  list-style: none;
}

.promo__direction {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  color: var(--basic-white);
}
.promo__direction::before {
  content: "";
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
}
.promo__direction:nth-child(1)::before {
  background-image: url("../img/directions/direction-1-icon.svg");
}
.promo__direction:nth-child(2)::before {
  background-image: url("../img/directions/direction-2-icon.svg");
}
.promo__direction:nth-child(3)::before {
  background-image: url("../img/directions/direction-3-icon.svg");
}
.promo__direction:nth-child(4)::before {
  background-image: url("../img/directions/direction-4-icon.svg");
}

.promo .btn--accent {
  display: block;
  margin: 0 auto;
  max-width: 224px;
  width: 100%;
}

@media (min-width: 500px) {
  html[lang=en] .promo .title--xl br:first-child {
    display: none;
  }
  html[lang=en] .promo .title--xl::after {
    right: -60px;
  }
  html[lang=ru] .promo .title--xl::after {
    right: -20px;
  }
}
@media (min-width: 768px) {
  .promo .btn--accent {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .promo {
    padding-top: 170px;
    padding-bottom: 100px;
    background-image: url("../img/decorative/combs-icon.svg");
    background-repeat: no-repeat;
    background-position: right top 26px;
  }
  .promo .title--xl {
    margin-bottom: 40px;
  }
  .promo .title--xl::after {
    top: -32px;
    width: 113px;
    height: 87px;
  }
  html[lang=en] .promo .title--xl::after {
    right: -100px;
  }
  html[lang=ru] .promo .title--xl::after {
    right: -30px;
  }
  .promo__text {
    margin-bottom: 40px;
    font-size: 18px;
  }
  .promo__directions {
    display: flex;
    column-gap: 40px;
  }
  .promo__direction {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media (min-width: 1440px) {
  .promo .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
.achievements {
  padding-top: 50px;
  padding-bottom: 50px;
  scroll-margin-top: 50px;
}

.achievements__list {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.achievements__item {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.achievements__term {
  font-size: 42px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--main-blue);
}

.achievements__desc {
  margin: 0;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  color: var(--text-color);
}

@media (min-width: 768px) {
  .achievements__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
  }
}
@media (min-width: 1200px) {
  .achievements {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .achievements__item {
    row-gap: 10px;
  }
  .achievements__term {
    font-size: 58px;
  }
  .achievements__desc {
    font-size: 18px;
    line-height: 1.4;
  }
}
@media (min-width: 1440px) {
  .achievements .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
.projects__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 25px;
  background-color: #f0f3f5;
  border-radius: 32px;
}

.projects .title--l {
  position: relative;
  margin-bottom: 15px;
}
.projects .title--l::after {
  content: "";
  position: absolute;
  top: -16px;
  right: 0;
  transform: rotate(90deg);
  width: 26px;
  height: 28px;
  background-image: url("../img/decorative/highlight-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.projects .title--l br {
  display: none;
}

.projects__title-desk {
  display: none;
}

.projects__text {
  margin: 0;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
}

.projects__list {
  display: grid;
  row-gap: 25px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.projects__item {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 25px;
  background-color: var(--basic-white);
  border-radius: 20px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.06);
}

.projects__item-desk {
  margin: 0;
  font-size: 15px;
  line-height: 1.5;
  color: var(--text-color);
}

@media (min-width: 449px) {
  html[lang=en] .projects .title--l::after {
    right: -20px;
  }
}
@media (min-width: 500px) {
  html[lang=ru] .projects .title--l::after {
    right: -20px;
  }
}
@media (min-width: 768px) {
  .projects__title-mob {
    display: none;
  }
  .projects__title-desk {
    display: block;
  }
  html[lang=ru] .projects .title--l::after {
    right: 0;
  }
  .projects__list {
    grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
    column-gap: 30px;
  }
  .projects__item-desk {
    margin-bottom: auto;
  }
}
@media (min-width: 1200px) {
  .projects__content {
    align-items: center;
    padding: 80px;
  }
  .projects .title--l {
    margin-bottom: 25px;
    text-align: center;
  }
  .projects .title--l::after {
    right: auto;
    left: 0;
    top: -27px;
    width: 40px;
    height: 43px;
    transform: rotate(0);
  }
  .projects .title--l br {
    display: block;
  }
  html[lang=ru] .projects .title--l::after {
    right: auto;
    left: 100px;
  }
  .projects__text {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
  }
  .projects__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.team {
  padding-top: 60px;
  padding-bottom: 60px;
}

.team .container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.team__info {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.team .title--l {
  position: relative;
  padding-bottom: 40px;
}
.team .title--l::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 101px;
  height: 15px;
  background-image: url("../img/decorative/border-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.team__text {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
}

.team__info .link {
  display: none;
}

.team__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: center;
  column-gap: 24px;
  row-gap: 36px;
  margin: 0;
  margin-bottom: 41px;
  padding: 0;
  list-style: none;
}

.team__item {
  position: relative;
}

.team__item-img {
  width: 150px;
  height: 220px;
  object-fit: cover;
  border-radius: 20px;
}

.team__item-info {
  position: absolute;
  bottom: -11px;
  left: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 5px 11px 0 0;
  background-color: var(--basic-white);
  border-top-right-radius: 10px;
}

.team__item-name {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 600;
  color: var(--basic-black);
}

.team__item-post {
  font-size: 10px;
  line-height: 1.6;
  font-weight: 700;
  text-transform: uppercase;
  color: #cccccc;
}

@media (min-width: 1200px) {
  .team {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .team .container {
    flex-direction: row;
    column-gap: 80px;
  }
  .team .title--l {
    padding-bottom: 45px;
  }
  .team .title--l::after {
    width: 142px;
    height: 20px;
  }
  .team__text {
    font-size: 18px;
    line-height: 1.4;
  }
  .team__info .link {
    display: flex;
  }
  .team__right {
    flex-shrink: 0;
  }
  .team__list {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    row-gap: 68px;
    margin-bottom: 0;
  }
  .team__item-img {
    width: 194px;
  }
  .team__item-info {
    bottom: -28px;
    padding: 8px 16px 0 0;
  }
  .team__item-name {
    font-size: 18px;
    line-height: 1.5;
  }
  .team__item-post {
    font-size: 13px;
    line-height: 1.7;
  }
  .team__right .link {
    display: none;
  }
}
@media (min-width: 1440px) {
  .team .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
.banner {
  scroll-margin-top: 60px;
}

.banner__content {
  background-color: var(--banner-bg);
  border-radius: 32px;
}

.banner__info {
  position: relative;
  padding-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
}
.banner__info::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--basic-black);
}

.banner__content .title--l {
  margin-bottom: 20px;
  color: var(--basic-white);
}

.banner__text {
  margin: 0;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}

.banner .btn--accent {
  display: block;
  max-width: 251px;
  min-width: 167px;
}

.banner__img {
  width: 100%;
  border-radius: 0 0 32px 32px;
}

@media (min-width: 990px) {
  .banner__content {
    display: flex;
  }
  .banner__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 0;
  }
  .banner__info::after {
    content: none;
  }
  .banner__content picture {
    flex-shrink: 0;
  }
  .banner__img {
    width: 576px;
    border-radius: 0 32px 32px 0;
  }
}
@media (min-width: 1200px) {
  .banner__content {
    column-gap: 80px;
  }
  .banner__info {
    padding-left: 80px;
  }
  .banner__content .title--l {
    margin-bottom: 25px;
  }
  .banner__text {
    margin-bottom: 40px;
    font-size: 18px;
  }
  .banner .btn--accent {
    margin-left: 0;
  }
}
.team-build {
  padding-top: 60px;
  padding-bottom: 60px;
}

.team-build .container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.team-build__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.team-build .title--l {
  position: relative;
  margin-bottom: 15px;
}
.team-build .title--l::after {
  content: "";
  position: absolute;
  top: -15px;
  right: -30px;
  transform: rotate(90deg);
  width: 27px;
  height: 28px;
  background-image: url("../img/decorative/highlight-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.team-build__text {
  margin: 0;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
}

.team-build__features {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  list-style: none;
}
.team-build__features li {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: var(--basic-black);
}
.team-build__features li::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #00c808;
  background-image: url("../img/icons/check-white-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

@media (min-width: 990px) {
  .team-build .container {
    flex-direction: row-reverse;
    column-gap: 30px;
  }
}
@media (min-width: 1200px) {
  .team-build {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .team-build .container {
    column-gap: 80px;
  }
  .team-build .title--l {
    margin-bottom: 25px;
  }
  .team-build .title--l::after {
    top: -42px;
    right: -40px;
    width: 40px;
    height: 43px;
  }
  .team-build__text {
    font-size: 18px;
    line-height: 1.4;
  }
  .team-build__features {
    row-gap: 20px;
  }
  .team-build__features li {
    column-gap: 20px;
    font-size: 18px;
  }
  .team-build__features li::before {
    width: 24px;
    height: 24px;
  }
  .team-build picture {
    flex-shrink: 0;
  }
  .team-build .link {
    margin-left: 44px;
  }
}
@media (min-width: 1440px) {
  .team-build .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
.contacts {
  padding-bottom: 60px;
}

.contacts__map {
  height: 550px;
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1440px 550px;
}
.contacts__map--en {
  background-image: url("../img/map/webp/map-en@1x.webp");
}
@media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
  .contacts__map--en {
    background-image: url("../img/map/webp/map-en@2x.webp");
  }
}
.contacts__map--ru {
  background-image: url("../img/map/webp/map-ru@1x.webp");
}
@media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
  .contacts__map--ru {
    background-image: url("../img/map/webp/map-ru@2x.webp");
  }
}

.contacts__info {
  position: relative;
  margin-top: -200px;
  padding: 40px 25px;
  scroll-margin-top: 45px;
  background-color: var(--basic-white);
  border-radius: 32px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.06);
}

.contacts__info .title--m {
  margin-bottom: 30px;
}

.contacts__content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.contacts__form {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.contacts__contact {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  margin: 0;
}

.contacts__contact-name {
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--text-color);
}

.contacts__contact-desc {
  margin-left: 0;
}

.contacts__contact-desc a[href^="tel:"] {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 600;
  color: var(--basic-black);
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;
}
@media (hover: hover), screen and (min-width: 0\0 ) {
  .contacts__contact-desc a[href^="tel:"]:hover {
    text-decoration-color: currentColor;
  }
}

.contacts__contact-desc a[href^="mailto:"] {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: var(--main-blue);
  text-decoration: underline;
  transition: color 0.3s ease;
}
@media (hover: hover), screen and (min-width: 0\0 ) {
  .contacts__contact-desc a[href^="mailto:"]:hover {
    color: #0057d2;
  }
}

.contacts__contact-desc address {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  font-style: normal;
  color: var(--basic-black);
}

@media (min-width: 990px) {
  .contacts__content {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 80px;
  }
  .contacts__form {
    flex-grow: 1;
    order: 1;
    max-width: 630px;
  }
  .contacts__form-btn {
    max-width: 375px;
  }
}
@media (min-width: 1200px) {
  .contacts__info {
    padding: 50px 80px;
  }
  .contacts__contact {
    row-gap: 20px;
  }
  .contacts__contact-name {
    font-size: 16px;
    line-height: 1.2;
  }
  .contacts__contact-desc a[href^="tel:"] {
    font-size: 28px;
  }
  .contacts__contact-desc a[href^="mailto:"] {
    font-size: 18px;
    line-height: 1.4;
  }
  .contacts__contact-desc address {
    font-size: 18px;
    line-height: 1.4;
  }
}
#messageSent {
  height: auto;
  background-color: #deff98;
  display: none;
  transition: 0.3s all ease;
}

.grecaptcha-badge {
  display: none !important;
}

#contactsEmail.field__input--err + label {
  top: 8px;
  font-size: 12px;
  line-height: 1.3;
  color: var(--main-blue);
}

.footer {
  padding-bottom: 30px;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  padding-top: 30px;
  border-top: 1px solid var(--basic-black);
}

.footer__logo {
  width: 109px;
  height: 27px;
}

.footer__copyright {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: rgba(16, 24, 40, 0.4);
}

@media (min-width: 768px) {
  .footer__content {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .footer {
    padding-bottom: 65px;
  }
  .footer__content {
    padding-top: 65px;
  }
  .footer__logo {
    width: 151px;
    height: 38px;
  }
  .footer__copyright {
    font-size: 15px;
  }
}
@media (min-width: 1440px) {
  .footer .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}