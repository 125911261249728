.banner {
  scroll-margin-top: 60px;
}

.banner__content {
  background-color: var(--banner-bg);
  border-radius: 32px;
}

.banner__info {
  position: relative;
  padding-top: 40px;
  padding-left: 25px;
  padding-right: 25px;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--basic-black);
  }
}

.banner__content .title--l {
  margin-bottom: 20px;
  color: var(--basic-white);
}

.banner__text {
  margin: 0;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: rgba($color: #ffffff, $alpha: 0.7);
}

.banner .btn--accent {
  display: block;
  max-width: 251px;
  min-width: 167px;
}

.banner__img {
  width: 100%;
  border-radius: 0 0 32px 32px;
}

@media (min-width: 990px) {
  .banner__content {
    display: flex;
  }

  .banner__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 0;

    &::after {
      content: none;
    }
  }

  .banner__content picture {
    flex-shrink: 0;
  }

  .banner__img {
    width: 576px;
    border-radius: 0 32px 32px 0;
  }
}

@include vp-1200 {
  .banner__content {
    column-gap: 80px;
  }

  .banner__info {
    padding-left: 80px;
  }

  .banner__content .title--l {
    margin-bottom: 25px;
  }

  .banner__text {
    margin-bottom: 40px;
    font-size: 18px;
  }

  .banner .btn--accent {
    margin-left: 0;
  }
}
