.promo {
  padding: 100px 0 60px;
  background-color: var(--promo-bg);
}

.promo .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.promo .title--xl {
  position: relative;
  margin-bottom: 20px;
  color: var(--basic-white);

  &::after {
    content: "";
    position: absolute;
    top: -15px;
    right: 0;
    width: 74px;
    height: 44px;
    background-image: url("../img/decorative/highlight-accent-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.promo__text {
  max-width: 700px;
  margin: 0;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: rgba($color: #fff, $alpha: 0.6);
}

.promo__directions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 25px;
  margin: 0;
  margin-bottom: 50px;
  padding: 0;
  list-style: none;
}

.promo__direction {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  color: var(--basic-white);

  &::before {
    content: "";
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
  }

  &:nth-child(1)::before {
    background-image: url("../img/directions/direction-1-icon.svg");
  }

  &:nth-child(2)::before {
    background-image: url("../img/directions/direction-2-icon.svg");
  }

  &:nth-child(3)::before {
    background-image: url("../img/directions/direction-3-icon.svg");
  }

  &:nth-child(4)::before {
    background-image: url("../img/directions/direction-4-icon.svg");
  }
}

.promo .btn--accent {
  display: block;
  margin: 0 auto;
  max-width: 224px;
  width: 100%;
}

@media (min-width: 500px) {
  html[lang="en"] .promo .title--xl br:first-child {
    display: none;
  }

  html[lang="en"] .promo .title--xl::after {
    right: -60px;
  }

  html[lang="ru"] .promo .title--xl::after {
    right: -20px;
  }
}

@include vp-768 {
  .promo .btn--accent {
    margin-left: 0;
    margin-right: 0;
  }
}

@include vp-1200 {
  .promo {
    padding-top: 170px;
    padding-bottom: 100px;
    background-image: url("../img/decorative/combs-icon.svg");
    background-repeat: no-repeat;
    background-position: right top 26px;
  }

  .promo .title--xl {
    margin-bottom: 40px;

    &::after {
      top: -32px;
      width: 113px;
      height: 87px;
    }
  }

  html[lang=en] .promo .title--xl::after {
    right: -100px;
  }

  html[lang=ru] .promo .title--xl::after {
    right: -30px;
  }

  .promo__text {
    margin-bottom: 40px;
    font-size: 18px;
  }

  .promo__directions {
    display: flex;
    column-gap: 40px;
  }

  .promo__direction {
    font-size: 18px;
    line-height: 1.5;
  }
}

@include vp-1440 {
  .promo .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}
