.lang {
  position: relative;
}

.lang__btn {
  display: flex;
  align-items: center;
  column-gap: 4px;
  height: 32px;
  padding: 5px 8px 5px 6px;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--basic-white);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;

  svg {
    width: 20px;
    height: 20px;
    fill: none;
    stroke: var(--basic-white);
    transition: stroke 0.3s ease;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      color: var(--basic-black);
      background-color: #eeeeee;
      border-color: #eeeeee;

      svg {
        stroke: var(--basic-black);
      }
    }
  }
}

.lang__dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  display: none;
  flex-direction: column;
  row-gap: 4px;
  width: 170px;
  margin: 0;
  padding: 10px;
  list-style: none;
  background-color: var(--basic-white);
  border-radius: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

  &--show {
    display: flex;
  }
}

.lang__link {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 500;
  color: #000000;
  border-radius: 10px;
  transition: background-color 0.3s ease;

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      background-color: #f4f4f4;
    }
  }

  &::before {
    content: "";
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
  }

  &--ru::before {
    background-image: url("../img/flags/ru-flag-icon.svg");
  }

  &--en::before {
    background-image: url("../img/flags/usa-flag-icon.svg");
  }

  &--current::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 9px;
    height: 8px;
    background-image: url("../img/icons/check-lang-icon.svg");
  }
}

@include vp-1200 {
  .lang__btn {
    column-gap: 4px;
    height: 40px;
    padding: 8px 10px;
    font-size: 16px;
    font-weight: 500;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
