.v-h {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.btn-reset {
  padding: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
